import { httpPost } from '~~assets/scripts/http';
import { $erroMess, downloadFile, filterDate } from '~~utils/utils';
import { CONFIG as PRO_CONFIG } from '~~assets/scripts/config';

const URLS = {
  week: 'webSearchCnt/GetWebSearchWeekResult',
  month: 'webSearchCnt/GetWebSearchMonthResult'
};

export default {
  name: 'modules',
  data () {
    return {
      // // 配置信息
      // id: String,
      // token: String,
      // 数据展示类型
      // 文本信息
      textItem: {
        title: '热门型号列表',
        dec: ['统计最近7天的型号搜索情况 (10.1~10.7)']
      }, // 操作信息
      optionItem: {
        isSearch: true,
        searchValueList: [
          {
            key: 1,
            binding: 'Model',
            value: '',
            placeholder: `搜型号`
          },
          {
            key: 2,
            binding: 'Brand',
            value: '',
            placeholder: `搜品牌`
          }
        ],
        produceBtn: false,
        uploadBtn: true
      }, // 列表信息
      listItem: {
        list: [],
        pageSize: 500,
        pageIndex: 1,
        pageTotal: 0,
        columns: [],
        isClumns: false
      },
      loading: true,
      DownloadFileName: '',
      searchData: {
        OrderBy: 0,
        // 搜索时间
        StartTime: '',
        EndTime: '',
        YearAndMonth: ''
      },
      isInit: false,
      selectType: 'week',

      dateItems: [],
      loadingText: '数据加载中....'
    };
  },
  methods: {
    // 页面初始化
    async onModuleInit (config) {
      await this.setConfig(config);
      await this.onInitDate('week', this.weekDate);
    },

    async isPurchased () {
      const data = await httpPost(this.token, `report/ispurchased/f1d95708-d88a-4040-bfae-6ebd02b8189f`);
      if (data.Code === 0 && data.Data) {
        return 'yes';
      } else if (data.Code === 11002) {
        return 'yes';
      } else if (data.Code === 11010) {
        return 'no';
      } else {
        return data.Message;
      }

    },

    async setConfig (config) {

      const { Name, Description } = config;
      this.textItem.dec = Description;
      this.textItem.title = Name.replace('new', '');

    }, // 获取数据
    async getList (params) {

      let res = null;
      const url = URLS[this.selectType];
      try {
        this.loading = true;
        res = await httpPost(this.token, url, params, { baseURL: '' });
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 200);

      }
      return res;
    }, // 搜索列表
    async onSearch (page = 1) {
      this.loadingText = '数据加载...';

      const params = this.onSetParams(page);
      console.log(params, 234234234);
      try {
        let data = null;
        if (this.selectType === 'day') {
          data = await this.getDayList();
        } else {
          data = await this.getList(params);
        }

        if (!data) return false;


        this.listItem.pageIndex = data.PageIndex;
        this.listItem.pageTotal = data['TotalRecord'];

        const dateTitle = data['Title'];
        if (!this.isInit && dateTitle) {
          const date = await this.onSetDate(dateTitle);
          if (date) {
            this.dateItems = date;
          }
          this.isInit = true;
        }

        let list = data['Result'];
        const type = this.selectType;
        list.forEach((item, index) => {
          item.sort = this.selectType !== 'day' ? index + 1 : item.sort;
          if (type === 'week') {
            item.type = 'week';
            item.Count = item['WeekCount'] || 0;
            item.Rank = item['RankCompareLastWeek'] || 0;
          }
          if (type === 'month') {
            item.type = 'month';
            item.Count = item['MonthCount'] || 0;
            item.Rank = item['RankCompareLastMonth'] || 0;
          }
        });
        this.listItem.list = list;


      } catch (err) {
        throw  err;
      } finally {
        console.log('请求结束');
      }
    },

    // 排序
    async onSort (val) {
      this.searchData.OrderBy = val;
      await this.onSearch(1);
    },
    onSetSearchParams () {
      const { searchValueList } = this.optionItem;
      let params = {
        Model: '',
        Brand: ''
      };
      searchValueList.forEach(item => {
        params[item.binding] = item.value;
      });
      return params;
    },

    onSetParams (page) {
      const pages = {
        // PageSize: this.listItem.pageSize,
        PageIndex: page
      };

      const searchParams = this.onSetSearchParams();
      const searchValue = {
        Model: searchParams.Model,
        Brand: searchParams.Brand,
        ...this.searchData,
        // ID: this.id,
        Limit: 1000
      };
      return Object.assign(pages, searchValue);
    },

    // 处理时间
    async onSetDate (data) {
      const dateList = data.split('&');
      return dateList.reduce((src, key) => {
        const list = key.split('/');
        const arr = list.reduce((a, b) => {
          const item = filterDate(new Date(b), 'MM月dd日');
          a.push(item);
          return a;
        }, []);
        src = src.concat(arr);
        return src;
      }, []);
    },

    // 下载处理
    // 生成文件
    async onGenerateFile () {
      const urls = {
        week: 'webSearchCnt/ExportDataBySearchWeek',
        month: 'webSearchCnt/ExportDataBySearchMonth'
      };

      const url = urls[this.selectType];

      let params = this.onSetParams(1);


      params.Limit = 3000;

      let res = null;
      try {
        this.loadingText = '报告下载中...';
        this.loading = true;
        res = await httpPost(this.token, url, params, { baseURL: 'res' });
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 200);

      }
      return res;
    },

    // 点击下载
    async onDownFile () {
      const isShow = await this.getPurchased();
      if (!isShow) return false;

      let url = '';
      if (this.selectType !== 'day') {
        const res = await this.onGenerateFile();

        if (!res || res.Code !== 0) {
          $erroMess('报告生成失败');
        }

        const id = res.Data;

        url = `${PRO_CONFIG.RES_URL}webSearchCnt/download/${id}`;
      } else {
        url = `${PRO_CONFIG.RES_URL}report/download/${this.DownloadFileName}`;
      }


      await downloadFile(url);
    },


    async getDayList () {
      const searchParams = this.onSetSearchParams();
      const params = {
        PageSize: 1000,
        PageIndex: this.listItem.pageIndex,
        SingleParams: {
          PartNo: searchParams.Model,
          BrandName: searchParams.Brand,
          ChangeOrderBy_: 0
        },
        configID: this.configId
      };

      let res = null;
      try {
        this.loading = true;
        res = await httpPost(this.token, `report/exec/${this.configId}`, params, { baseURL: 'res' });

        if (res) {
          res.Code = 200;
          res.Message = '成功';

          const list = res.Datas;

          // Brand:"TI(德州仪器)"
          // Count:0
          // CreateTime:"2023-08-28 03:40:01"
          // EndTime:"2023-08-27 00:00:00"
          // ID:23463572
          // Keyword:"LM358"
          // LastSearchTime:"2023-08-27 00:00:00"
          // Rank:0
          // RankCompareLastWeek:0
          // RankInt:1
          // SearchCnt:3884
          // SearchCompanyCnt:304
          // StartTime:"2023-08-21 00:00:00"
          // URL:"https://www.bom.ai/ic/LM358.html"
          // WeekCount:0
          // WeekInt:35
          // sort:1
          // type:"week

          res.Result = list.map(item => {
            return {
              Brand: item['Y_品牌'],
              Keyword: item['Y_型号'],
              SearchCnt: item['Y_搜索次数'],
              SearchCompanyCnt: item['Y_搜索公司数'],
              type: 'day',
              sort: item['排名'],
              RankInt: item['排名'],
              URL: item['型号_URL']
            };
          });

          this.DownloadFileName = res.DownloadFileName;
        }

      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
      return res;
    }
    // 处理头部


  }
};
